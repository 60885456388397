<template>
  <div class="i-wrap" ref="printPage">
    <div class="i-main">
      <div class="red-wrap">
        <div class="red-header">
          <div class="header-title">红字增值税专用发票信息表</div>
          <el-button v-if="!isPrint" class="print" type="primary" @click="handlePrint">打印</el-button>
          <div class="header-items">
            <div>
              <label>填开日期：</label>
              <span>{{fmtTime(info.invoiceTime)}}</span>
            </div>
            <div>
              <label>申请方经办人：</label>
              <span>{{nickName}}</span>
            </div>
          </div>
        </div>
        <div class="red-content">
          <div class="red-info">
            <div>
              <div class="info-label">销售方</div>
              <div class="info-area">
                <div>
                  <label class="required">名 称：</label>
                  <span>{{ info.sellerName }}</span>
                </div>
                <div>
                  <label>税 号：</label>
                  <span>{{ info.sellerTaxNo }}</span>
                </div>
                <div>
                  <label>地址、电话:</label>
                  <span>{{ info.sellerAddressTel }}</span>
                </div>
                <div>
                  <label>开户行及账号:</label>
                  <span>{{ info.sellerBankAccount }}</span>
                </div>
              </div>
            </div>
            <div>
              <div class="info-label">购买方</div>
              <div class="info-area">
                <div>
                  <label>名 称：</label>
                  <span>{{ info.buyerName }}</span>
                </div>
                <div>
                  <label>税 号：</label>
                  <span>{{ info.buyerTaxNo }}</span>
                </div>
                <div>
                  <label>地址、电话:</label>
                  <span>{{ info.buyerAddressTel }}</span>
                </div>
                <div>
                  <label>开户行及账号:</label>
                  <span>{{ info.buyerBankAccount }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="red-items">
            <table>
              <thead>
              <tr>
                <th>货物或应税劳务、服务名称</th>
                <th>规格型号</th>
                <th>单位</th>
                <th>数量</th>
                <th>单价（含税）</th>
                <th>金额（含税）</th>
                <th>税率</th>
                <th>税额</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in info.items" :key="index">
                <td>*{{item.taxClassName}}*{{item.merchandiseName}}</td>
                <td>{{item.specification}}</td>
                <td>{{item.unit}}</td>
                <td>{{item.quantity}}</td>
                <td>{{fmtMoney(item.price)}}</td>
                <td>{{fmtMoney(item.sumAmount)}}</td>
                <td>{{fmtTaxRate(item.taxRate)}}</td>
                <td>{{fmtMoney(item.taxAmount)}}</td>
              </tr>
              <tr>
                <td>开票合计</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{fmtMoney(info.sumAmount)}}</td>
                <td></td>
                <td>{{fmtMoney(info.taxAmount)}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="red-remark">
            <div class="remark-title">说明</div>
            <div class="remark-content">
              <el-row>
                <el-col :span="12">
                  <el-radio v-model="info.redSource"  label="buyer" :disabled="info.redSource!='buyer'">购买方申请</el-radio>
                </el-col>
                <el-col :span="12">
                  <el-radio v-model="info.redSource" label="seller" :disabled="info.redSource=='buyer'" >销售方申请</el-radio>
                </el-col>
              </el-row>
              <el-row class="deduction">
                <el-radio v-model="info.deductionType" :label="1" :disabled="!(info.redSource=='buyer'&&info.deductionType!=0)">已抵扣</el-radio>
                <el-radio v-model="info.deductionType" :label="0" :disabled="!(info.redSource=='buyer'&&info.deductionType==0)">未抵扣</el-radio>
              </el-row>
              <el-row class="black">
                对应蓝字专用发票的代码：{{info.invoiceCode}} 号码：{{info.invoiceNo}}
              </el-row>
            </div>
          </div>
          <div class="red-remark">
            <div class="remark-title notice-no">红字发票信息表编号</div>
            <div class="remark-content">{{info.redNoticeNo}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import printJS from 'print-js'

export default {
  name: 'BillingInvoiceDetail',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      isPrint: false,
      // 申请方经办人
      nickName: localStorage.getItem('nickName')
    }
  },
  methods: {
    // 详情打印
    handlePrint () {
      this.isPrint = true
      this.$nextTick(() =>{
        html2canvas(this.$refs.printPage, {
          backgroundColor: null,
          useCORS: true,
          windowHeight: document.body.scrollHeight,
        }).then((canvas) => {
          this.isPrint = false
          const url = canvas.toDataURL()
          this.img = url
          printJS({
            printable: url,
            type: 'image',
            documentTitle: '打印图片'
          })
        })
      })

    },
    fmtMoney (val) {
      return val ? '¥' + val : ''
    },
    fmtTaxRate (val) {
      return val ? val * 100 + '%' : ''
    },
    fmtTime (val) {
      return this.$moment(val).format('YYYY-MM-DD')
    }
  }
}
</script>

<style lang="scss" scoped>

  table {
    width: 100%;
    border-collapse: collapse;

    td, th {
      font-weight: 400;
      border: 1px solid #9D5224;
      height: 32px;
      line-height: 32px;
    }
  }

  .i-wrap {
    font-size: 14px;
    margin-top: -50px;
    min-height: 100%;
    background-color: #FFFFFF;

    td,.black {
      color: #333333;
    }
  }

  .i-main {
    padding: 24px;

    ::v-deep .el-input.is-disabled .el-input__inner {
      color: #666666;
      font-weight: 400;
    }
    ::v-deep .el-radio__input.is-disabled+span.el-radio__label {
      color: #666666;
      font-weight: 400;
    }
  }

  .red-wrap {
    display: flex;
    padding: 24px;
    color: #9D5224;
    align-items: center;
    flex-direction: column;
    border: 1px solid #E5E5E5;

    .red-header {
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      margin-bottom: 18px;
      flex-direction: column;

      .header-title {
        font-size: 32px;
        margin-bottom: 28px;
        border-bottom: 4px double;
      }

      .print {
        margin-left: 500px;
        margin-top: 20px;
        position: absolute;
      }

      .header-items {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        div:first-child {
          margin-right: 100px;
        }

        span {
          padding-left: 10px;
          color: #333333;
        }
      }
    }

    .red-content {
      width: 100%;
      display: flex;
      flex-direction: column;

      .red-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        justify-content: space-between;

        .info-label {
          width: auto;
          display: flex;
          padding: 24px 11px;
          letter-spacing: 6px;
          align-items: center;
          justify-content: center;
          border-right: 1px solid;
          writing-mode: vertical-lr;
        }

        .info-area {
          width: 100%;
          padding: 18px 0 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          div {
            min-height: 48px;
          }

          label {
            font-weight: 400;
            width: 100px;
            color: #9D5224;
            text-align: left;
            display: inline-block;
            vertical-align: top;
          }

          span {
            text-align: left;
            width: 300px;
            color: #333333;
            display: inline-block;
          }

          .sellerName {
            width: 300px;
          }

          .sellerName ::v-deep .el-input {
            width: 100%;
            border: none;
          }

          .el-input {
            width: 300px;
            border: none;
          }

          ::v-deep .el-input__inner {
            border: none;
            border-radius: 0;
            background-color: #FFFFFF;
          }
        }
      }

      .red-info > div {
        width: 48%;
        display: flex;
        border: 1px solid;
        flex-direction: row;
        min-height: 200px;
      }

      .red-remark {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        border: 1px solid;

        .remark-title {
          width: auto;
          padding: 24px 11px;
          letter-spacing: 6px;
          border-right: 1px solid;
          writing-mode: vertical-lr;
        }

        .notice-no {
          width: 60px;
          padding: 10px 8px;
          letter-spacing: 2px;
          writing-mode: unset;
          word-break: break-word;
        }

        .remark-content {
          flex: auto;
          padding: 24px;
          text-align: left;

          .deduction {
            display: flex;
            padding: 24px 24px;
            flex-direction: row;
          }
        }
      }
    }
  }

  ::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #333333;
    font-weight: 400;
  }
</style>
